import { CometChat } from '@cometchat-pro/chat'
import { AxiosInstance } from 'axios'
import { FriendRequestStatus } from '../enums/FriendRequestStatus'
import friendRequestApi from '../services/onlineChat/friendRequestApi'
import userApi from "../services/onlineChat/userApi"
import fetchUsers from './fetchUsers'

export default function fetchFriendRequests(axiosInstance: AxiosInstance) {
    return new Promise((resolve, reject) => {
        CometChat.getLoggedinUser()
            .then(receiver => {
                const allFetchFriendRequestPromises: Promise<any>[] = []
                function loop(currentPage: number) {
                    fetchFriendRequestsByPage((receiver!.getMetadata() as any)?.loginId, (receiver!.getMetadata() as any)?.cbdsUid, currentPage, axiosInstance)
                        .then(({ friendRequests, pagination }) => {
                            if (friendRequests.length > 0) {
                                allFetchFriendRequestPromises.push(completeFriendRequest(friendRequests, receiver!, axiosInstance))
                            }
                            if (pagination.currentPage < pagination.totalPages) {
                                loop(currentPage + 1)
                            } else {
                                Promise.all(allFetchFriendRequestPromises)
                                    .then(friendRequestsArray => resolve(friendRequestsArray.flat()))
                                    .catch(reject)
                            }
                        })
                        .catch(reject)
                }
                loop(1)
            })
            .catch(reject)
    })
}

function fetchFriendRequestsByPage(receiverLoginId: string, receiverCompany: string, currentPage: number, axiosInstance: AxiosInstance) {
    return friendRequestApi.list(axiosInstance, {
        receiverLoginId,
        receiverCompany,
        status: FriendRequestStatus.PENDING,
        perPage: 30,
        currentPage,
    }).then(response => response.data)
}

function completeFriendRequest(friendRequests: any[], receiver: any, axiosInstance: AxiosInstance) {
    const senderLoginIds = friendRequests.map(friendRequest => friendRequest.senderLoginId)
    return userApi.list(axiosInstance, { loginIdIn: senderLoginIds })
        .then(response => response.data.data?.map(user => user.uid) ?? [])
        .then(senderUids => {
            const userRequest = new CometChat.UsersRequestBuilder()
                .setLimit(senderUids.length)
                .setUIDs(senderUids)
                .build()
            return fetchUsers(userRequest, axiosInstance)
                .then(users => {
                    const userMap = users.reduce((acc, user) => {
                        acc[user.metadata.loginId] = user
                        return acc
                    }, {})
                    return friendRequests.map(fr => ({
                        id: fr.id,
                        sender: userMap[fr.senderLoginId],
                        receiver,
                        request: fr.request,
                        requestTime: fr.requestTime,
                        status: fr.status
                    }))
                })
        })
}