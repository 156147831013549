import { AxiosInstance } from "axios"

interface DefaultContactAPIResponse {
    cbdsUid: string,
    loginId: string,
}

const defaultContactApi = {
    get: function (axiosInstance: AxiosInstance, data: { cbdsUid: string }) {
        return axiosInstance.get<DefaultContactAPIResponse[]>('/common-online-chat-api/default-contacts/:cbdsUid', { pathVars: { cbdsUid: data.cbdsUid } })
    }
}

export default defaultContactApi