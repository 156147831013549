
import { CometChat } from "@cometchat-pro/chat";
import PropTypes from "prop-types";
import { useContext } from "react";
import Translator from "../../../resources/localization/translator";
import { theme } from "../../../resources/theme";
import { CometChatContext } from "../../../util/CometChatContext";
import { CometChatAvatar, CometChatUserPresence } from "../../Shared";
import { itemDescStyle, itemDetailStyle, itemNameStyle, itemThumbnailStyle, listItem } from "./style";




const CometChatUserListItem = (props) => {

	const context = useContext(CometChatContext);

	let userPresence = (<CometChatUserPresence status={props.user.status} />);

	const toggleTooltip = (event, flag) => {

		const elem = event.target;

		const scrollWidth = elem.scrollWidth;
		const clientWidth = elem.clientWidth;

		if (scrollWidth <= clientWidth) {
			return false;
		}

		if (flag) {
			elem.setAttribute("title", elem.textContent);
		} else {
			elem.removeAttribute("title");
		}
	}

	const userName = props.user.name
	const description = props.user.metadata.cbdsName ?? ''

	return (
		<div css={listItem(props, context)} onClick={() => props.clickHandler(props.user)} className="list__item">
			<div css={itemThumbnailStyle()} className="list__item__thumbnail">
				<CometChatAvatar user={props.user} />
				{userPresence}
			</div>
			<div css={itemDetailStyle()} className="list__item__details" dir={Translator.getDirection(context.language)}>
				<div css={itemNameStyle(context)} className="item__details__name"
					onMouseEnter={event => toggleTooltip(event, true)}
					onMouseLeave={event => toggleTooltip(event, false)}>{props.display ? userName : userName}</div>
				<div css={itemDescStyle(context)} className="item__details__desc">{description}</div>
			</div>
		</div>
	)
}

// Specifies the default values for props:
CometChatUserListItem.defaultProps = {
	theme: theme,
	user: {},
	display: false
};

CometChatUserListItem.propTypes = {
	theme: PropTypes.object,
	user: PropTypes.shape(CometChat.User),
	display: PropTypes.bool
};

export { CometChatUserListItem };

