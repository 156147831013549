import { createGenerateClassName, CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core'
import { History } from 'history'
import { GenerateId } from 'jss'
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch } from "react-redux"
import { AppProps } from "single-spa"
import appConfig from './configs/appConfig'
import { messages } from "./configs/i18n/messages"
import { themes } from './configs/theme/themes'
import { ApplicationProxy } from "./layouts/Application/ApplicationProxy"
import { applicationActions, useApplicationSelector } from './layouts/Application/applicationSlice'
import { OnlineChatContainer } from "./layouts/OnlineChat/OnlineChatContainer"
import { ReduxProvider } from './store/ReduxProvider'
import { SharedStore } from './store/SharedStore'
import { FunctionStore } from "./utils/FunctionStore"

export interface RootProps {
  history: History,
  store: SharedStore,
  functionStore: FunctionStore,
}

const RootContext = createContext<RootProps>(undefined as any)

export function Root(props: RootProps & AppProps) {
  return <React.StrictMode>
    <RootContext.Provider value={props}>
      <StoreProvider>
        <I18nProvider>
          <MuiProvider>
            <OnlineChatContainer />
            <ApplicationProxy />
          </MuiProvider>
        </I18nProvider>
      </StoreProvider>
    </RootContext.Provider>
  </React.StrictMode>
}

function StoreProvider({ children }: { children: React.ReactElement }) {
  const context = useContext(RootContext)
  return <ReduxProvider store={context.store}>
    {children}
  </ReduxProvider>
}

function I18nProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(applicationActions.loadMessage(messages))
  }, [dispatch])


  const language = useApplicationSelector(state => state.i18n.language)
  const timezone = useApplicationSelector(state => state.i18n.timezone)
  const localeMessages = useApplicationSelector(state => state.i18n.messages[state.i18n.language])
  const defaultRichTextElements = useMemo(() => {
    const mapChunk = (field: string) => localeMessages[field] ?? field
    return {
      field: (chunk: any) => <span>{mapChunk(chunk)}</span>,
      code: (chunk: any) => <span>{mapChunk(chunk)}</span>
    }
  }, [localeMessages])

  return <IntlProvider locale={language} timeZone={timezone} messages={localeMessages} defaultRichTextElements={defaultRichTextElements}>
    {children}
  </IntlProvider>
}


function MuiProvider({ children }: { children: React.ReactNode }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateClassName = useCallback<GenerateId>(createGenerateClassName({
    productionPrefix: appConfig.projectName,
    seed: appConfig.projectName,
  }), [])
  const theme = useApplicationSelector(state => state.ui.theme)
  return <StylesProvider generateClassName={generateClassName}>
    <ThemeProvider theme={themes[theme]}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </StylesProvider>
}

export function useFunctionStore() {
  const context = useContext(RootContext)
  return context.functionStore
}