import { useDispatch } from "react-redux"
import { CometChatUI } from '../../components/CometChat'
import { useApplicationSelector } from '../Application/applicationSlice'
import { useOnlineChatSelector } from './onlineChatSlice'

export function OnlineChat() {
    const newFriendRequestCount = useOnlineChatSelector(state => state.newFriendRequestCount)
    const language = useApplicationSelector(state => state.i18n.language)
    const dispatch = useDispatch()
    return <CometChatUI lang={language} dispatch={dispatch} newFriendRequestCount={newFriendRequestCount} />
}
