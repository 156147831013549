import { CometChat } from '@cometchat-pro/chat'
import { AxiosInstance } from 'axios'
import fetchUsersMetadata from './fetchUsersMetadata'

export default function fetchUsers(userRequest: CometChat.UsersRequest, axiosInstance: AxiosInstance) {
    return new Promise<any[]>((resolve, reject) => {
        const allFetchUsersMetadataPromises: Promise<any>[] = []
        function loop() {
            userRequest.fetchNext()
                .then(users => {
                    if (users.length > 0) {
                        loop()
                        allFetchUsersMetadataPromises.push(fetchUsersMetadata(users, axiosInstance))
                    } else {
                        Promise.all(allFetchUsersMetadataPromises)
                            .then(userss => resolve(userss.flat()))
                            .catch(reject)
                    }
                })
                .catch(reject)
        }
        loop()
    })
}