import { CometChat } from '@cometchat-pro/chat'
import { AxiosInstance } from 'axios'
import fetchGroupMembers from './fetchGroupMembers'
import fetchUsersMetadata from './fetchUsersMetadata'

export default function fetchConversations(conversationRequest: CometChat.ConversationsRequest, axiosInstance: AxiosInstance) {
    return new Promise((resolve, reject) => {
        const allConversations: any[] = []
        const allFetchConversationWithPromises: Promise<any>[] = []
        function loop() {
            conversationRequest.fetchNext()
                .then(conversations => {
                    if (conversations.length > 0) {
                        loop()
                        allConversations.push(...conversations)
                        // groups
                        const groups = conversations.filter(conversation => conversation.getConversationType() === CometChat.ACTION_TYPE.TYPE_GROUP)
                            .map(conversation => conversation.getConversationWith() as CometChat.Group)
                        groups.forEach(group => allFetchConversationWithPromises.push(fetchGroupMembers(group, axiosInstance)))
                        // users
                        const users = conversations.filter(conversation => conversation.getConversationType() === CometChat.ACTION_TYPE.TYPE_USER)
                            .map(conversation => conversation.getConversationWith() as CometChat.User)
                        allFetchConversationWithPromises.push(fetchUsersMetadata(users, axiosInstance))
                    } else {
                        Promise.all(allFetchConversationWithPromises)
                            .then(() => resolve(allConversations.flat()))
                            .catch(reject)
                    }
                })
                .catch(reject)
        }
        loop()
    })
}