import React from "react"
import { applicationActions } from '../../../../../layouts/Application/applicationSlice'
import friendRequestApi from '../../../../../services/onlineChat/friendRequestApi'
import errorToNotification from '../../../../../utils/axios/errorToNotification'
import responseToNotification from '../../../../../utils/axios/responseToNotification'
import shareAxiosInstance from '../../../../../utils/axios/shareAxiosInstance'
import Translator from "../../../resources/localization/translator"
import { CometChatContext } from "../../../util/CometChatContext"
import { CometChatBackdrop } from "../../Shared"
import { SendFriendRequestManager } from './controller'
import closeIcon from "./resources/close.svg"
import creatingIcon from "./resources/creating.svg"
import infoIcon from "./resources/info.svg"
import {
	alphabetStyle,
	inputStyle, modalBodyStyle, modalCloseStyle, modalErrorStyle, modalInfoStyle, modalTableStyle, modalWrapperStyle, tableBodyStyle, tableCaptionStyle, tableFootStyle, textAreaStyle
} from "./style"

class CometChatSendFriendRequest extends React.Component {
	static contextType = CometChatContext;

	constructor(props) {
		super(props)

		this.state = {
			errorMessage: "",
			cbdsUid: "",
			loginId: "",
			request: "",
			cbdsFilter: "",
			userFilter: "",
			cbdsList: [],
			userList: [],
			sending: false,
		}
	}

	componentDidMount() {
		this.manager = new SendFriendRequestManager(this.context)
		this.manager.fetchTeams()
			.then(teams => this.setState({ cbdsList: teams }))
			.catch(error => this.context.dispatch(applicationActions.pushNotification(errorToNotification(error))))
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.cbdsUid !== prevState.cbdsUid) {
			this.manager = new SendFriendRequestManager(this.context)
			this.manager.fetchTeams()
				.then(teams => this.setState({ cbdsList: teams }))
				.catch(error => this.context.dispatch(applicationActions.pushNotification(errorToNotification(error))))
		}

		if (this.state.cbdsUid !== prevState.cbdsUid) {
			if (this.state.cbdsUid) {
				this.manager.fetchUsers(this.state.cbdsUid)
					.then(users => {
						const defaultUser = users.find(user => user.default)
						this.setState({ userList: users, userFilter: '', loginId: defaultUser ? defaultUser.loginId : '' })
					})
					.catch(error => this.context.dispatch(applicationActions.pushNotification(errorToNotification(error))))
			} else {
				this.setState({ userList: [] })
			}
		}
	}

	componentWillUnmount() {
	}

	cbdsChangeHandler = event => {
		this.setState({ cbdsUid: event.target.value })
	};

	userChangeHandler = event => {
		this.setState({ loginId: event.target.value })
	};

	requestChangeHandler = event => {
		this.setState({ request: event.target.value })
	}

	enableSend = () => {
		return Boolean(this.state.cbdsUid) && Boolean(this.state.loginId)
	};

	createGroup = () => {
		this.setState({ sending: true })
		const { loginId, request, cbdsUid } = this.state

		const axiosInstance = shareAxiosInstance(this.context.dispatch, this.context.options)
		friendRequestApi.create(axiosInstance, {
			senderLoginId: this.context.options.loginId,
			senderCompany: this.context.options.companyUid,
			receiverLoginId: loginId,
			receiverCompany: cbdsUid,
			request
		})
			.then(response => {
				this.context.dispatch(applicationActions.pushNotification({
					...responseToNotification(response),
					messages: { code: 'common.onilnechat.friendRequest.sent' }
				}))
				this.props.close()
			})
			.catch(error => this.context.dispatch(applicationActions.pushNotification(errorToNotification(error))))
	};

	changeCbdsFilter = (c) => {
		this.setState(state => state.cbdsFilter === c ? { cbdsFilter: "" } : { cbdsFilter: c })
	}

	changeUserFilter = (c) => {
		this.setState(state => state.userFilter === c ? { userFilter: "" } : { userFilter: c, loginId: "" })
	}

	render() {
		const alphabet = new Array(27).fill(0).map((v, i) => i < 26 ? String.fromCharCode(65 + i) : "#")
		const cbdsListOptions = this.state.cbdsList.filter(cbds => {
			const firstChar = cbds.cbdsName.charAt(0).toUpperCase()
			if (this.state.cbdsFilter === '') {
				return true
			} else if (this.state.cbdsFilter >= 'A' && this.state.cbdsFilter <= 'Z') {
				return firstChar === this.state.cbdsFilter
			} else {
				return firstChar < 'A' || firstChar > 'Z'
			}
		}).map(cbds => {
			return <option value={cbds.cbdsUid} key={cbds.cbdsUid}>
				{cbds.cbdsName}
			</option>
		})
		const cbdsAlphabetMap = {}
		this.state.cbdsList.forEach(cbds => {
			const firstChar = cbds.cbdsName.charAt(0).toUpperCase()
			if (firstChar >= 'A' && firstChar <= 'Z') {
				cbdsAlphabetMap[firstChar] = true
			} else {
				cbdsAlphabetMap['#'] = true
			}
		})
		const cbdsAlphabet = []
		for (const char of alphabet) {
			const disabled = !cbdsAlphabetMap[char]
			const selected = this.state.cbdsFilter === char
			cbdsAlphabet.push(<button
				key={char}
				tabIndex={disabled ? -1 : 0}
				css={alphabetStyle(this.context.theme, disabled, selected)}
				onClick={() => this.changeCbdsFilter(char)}>
				{char}
			</button>)
		}

		const userListOptions = this.state.userList.filter(user => {
			const firstChar = user.name.charAt(0).toUpperCase()
			if (this.state.userFilter === '') {
				return true
			} else if (this.state.userFilter >= 'A' && this.state.userFilter <= 'Z') {
				return firstChar === this.state.userFilter
			} else {
				return firstChar < 'A' || firstChar > 'Z'
			}
		}).map(user => {
			return <option value={user.account.loginId} key={user.account.loginId}>
				{user.name}
			</option>
		})
		const userAlphabetMap = {}
		this.state.userList.forEach(user => {
			const firstChar = user.name.charAt(0).toUpperCase()
			if (firstChar >= 'A' && firstChar <= 'Z') {
				userAlphabetMap[firstChar] = true
			} else {
				userAlphabetMap['#'] = true
			}
		})
		const userAlphabet = []
		for (const char of alphabet) {
			const disabled = !userAlphabetMap[char]
			const selected = this.state.userFilter === char
			userAlphabet.push(<button
				key={char}
				tabIndex={disabled ? -1 : 0}
				css={alphabetStyle(this.context.theme, disabled, selected)}
				onClick={() => this.changeUserFilter(char)}>
				{char}
			</button>)
		}

		const createText = this.state.creatingGroup ? Translator.translate("SENDING", this.context.language) : Translator.translate("SEND", this.context.language)

		return (
			<React.Fragment>
				<CometChatBackdrop show={true} clicked={this.props.close} />
				<div css={modalWrapperStyle(this.context)} className="modal__creategroup">
					<span css={modalCloseStyle(closeIcon, this.context)} className="modal__close" onClick={this.props.close} title={Translator.translate("CLOSE", this.context.language)}></span>
					<span css={modalInfoStyle(infoIcon, this.context)} className="modal__info" title={Translator.translate("SEND_FRIEND_REQUEST_HELP", this.context.language)}></span>
					<div css={modalBodyStyle()} className="modal__body">
						<table css={modalTableStyle(this.props)}>
							<caption css={tableCaptionStyle()} className="modal__title"> {Translator.translate("SEND_FRIEND_REQUEST", this.context.language)} </caption>
							<tbody css={tableBodyStyle()} className="modal__search">
								<tr>
									<td><div css={modalErrorStyle(this.context)}>{this.state.errorMessage}</div></td>
								</tr>
								<tr>
									<td>
										<div>{cbdsAlphabet}</div>
										<select disabled={cbdsListOptions.length === 0} css={inputStyle(this.props)} className="grouptype" onChange={this.cbdsChangeHandler} value={this.state.cbdsUid} tabIndex="1">
											<option value="">{Translator.translate("SELECT_A_TEAM", this.context.language)}</option>
											{cbdsListOptions}
										</select>
									</td>
								</tr>
								<tr>
									<td>
										<div>{userAlphabet}</div>
										<select disabled={userListOptions.length === 0} css={inputStyle(this.props)} className="grouptype" onChange={this.userChangeHandler} value={this.state.loginId} tabIndex="2">
											<option value="">{Translator.translate("SELECT_A_USER", this.context.language)}</option>
											{userListOptions}
										</select>
									</td>
								</tr>
								<tr>
									<td>
										<textarea autoComplete="off" css={textAreaStyle(this.props)} className="search__input" placeholder={Translator.translate("ENTER_REQUEST_MESSAGE", this.context.language)} type="text" tabIndex="3" onChange={this.requestChangeHandler} value={this.state.request} />
									</td>
								</tr>
							</tbody>
							<tfoot css={tableFootStyle(this.context, this.state, creatingIcon)}>
								<tr className="sendfriendrequest">
									<td><button disabled={!this.enableSend()} type="button" tabIndex="4" onClick={this.createGroup}><span>{createText}</span></button></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export { CometChatSendFriendRequest }

