import { CometChat } from "@cometchat-pro/chat";
import dateFormat from "dateformat";
import PropTypes from "prop-types";
import React from "react";
import Translator from "../../../resources/localization/translator";
import { theme } from "../../../resources/theme";
import { CometChatContext } from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";
import { CometChatAvatar, CometChatBackdrop, CometChatUserPresence } from "../../Shared";
import { FileSenderManager } from "./controller";
import closeIcon from "./resources/close.svg";
import creatingIcon from "./resources/creating.svg";
import {
    chatDetailStyle, chatNameStyle, chatThumbnailStyle,
    chatUserStyle, fileListStyle, fileStyle, modalBodyStyle, modalCloseStyle, modalTableStyle, modalWrapperStyle, tableBodyStyle, tableCaptionStyle, tableFootStyle
} from "./style";

class CometChatFileSender extends React.Component {
    item;
    static contextType = CometChatContext;

    constructor(props) {
        super(props);

        this.state = {
            status: "",
            presence: "offline",
            enableUserPresence: false,
        };
    }

    componentDidMount() {

        CometChat.getLoggedinUser()
            .then(user => (this.loggedInUser = user))
            .catch(error => this.props.actionGenerated(enums.ACTIONS["ERROR"], [], "SOMETHING_WRONG"));

        this.MessageHeaderManager = new FileSenderManager();
        this.MessageHeaderManager.attachListeners(this.updateHeader);

        if (this.context.type === CometChat.ACTION_TYPE.TYPE_USER) {
            this.setStatusForUser();
        } else if (this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP) {
            this.setStatusForGroup();
        }

        this.item = this.context.item;

        this.enableUserPresence();
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.context.type === CometChat.ACTION_TYPE.TYPE_USER && (this.item !== this.context.item || prevProps.lang !== this.props.lang)) {
            this.setStatusForUser();
        } else if (this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP && (this.item !== this.context.item || prevProps.lang !== this.props.lang)) {
            this.setStatusForGroup();
        }

        this.item = this.context.item;

        this.enableUserPresence();
    }

    setStatusForUser = () => {
        let status = "";
        const presence = this.context.item.status === CometChat.USER_STATUS.ONLINE ? CometChat.USER_STATUS.ONLINE : CometChat.USER_STATUS.OFFLINE;

        if (this.context.item.status === CometChat.USER_STATUS.OFFLINE && this.context.item.lastActiveAt) {
            const lastActive = this.context.item.lastActiveAt * 1000;
            const messageDate = dateFormat(lastActive, "dS mmm yyyy, h:MM TT");

            status = `${Translator.translate("LAST_ACTIVE_AT", this.props.lang)}: ${messageDate}`;
        } else if (this.context.item.status === CometChat.USER_STATUS.OFFLINE) {
            status = Translator.translate("OFFLINE", this.props.lang);
        } else if (this.context.item.status === CometChat.USER_STATUS.ONLINE) {
            status = Translator.translate("ONLINE", this.props.lang);
        }

        this.setState({ status: status, presence: presence });
    };

    setStatusForGroup = () => {
        let membersText = Translator.translate("MEMBERS", this.props.lang);
        const status = `${this.context.item.membersCount} ${membersText}`;
        this.setState({ status: status });
    };

    componentWillUnmount() {
        this.MessageHeaderManager.removeListeners();
        this.MessageHeaderManager = null;
    }

    updateHeader = (key, item, groupUser) => {
        switch (key) {
            case enums.USER_ONLINE:
            case enums.USER_OFFLINE: {
                if (this.context.type === CometChat.ACTION_TYPE.TYPE_USER && this.context.item.uid === item.uid) {

                    //if user presence feature is disabled
                    if (this.state.enableUserPresence === false) {
                        return false;
                    }

                    let status = "";
                    if (item.status === CometChat.USER_STATUS.OFFLINE) {
                        status = Translator.translate("OFFLINE", this.context.language);
                    } else if (item.status === CometChat.USER_STATUS.ONLINE) {
                        status = Translator.translate("ONLINE", this.context.language);
                    }
                    this.setState({ status: status, presence: item.status });
                }
                break;
            }
            case enums.GROUP_MEMBER_KICKED:
            case enums.GROUP_MEMBER_BANNED:
            case enums.GROUP_MEMBER_LEFT:
                if (this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP && this.context.item.guid === item.guid && this.loggedInUser?.uid !== groupUser?.uid) {
                    let membersCount = parseInt(item.membersCount);
                    const status = `${membersCount} ${Translator.translate("MEMBERS", this.context.language)}`;
                    this.setState({ status: status });
                }
                break;
            case enums.GROUP_MEMBER_JOINED:
                if (this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP && this.context.item.guid === item.guid) {
                    let membersCount = parseInt(item.membersCount);
                    const status = `${membersCount} ${Translator.translate("MEMBERS", this.context.language)}`;
                    this.setState({ status: status });
                }
                break;
            case enums.GROUP_MEMBER_ADDED:
                if (this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP && this.context.item.guid === item.guid) {
                    let membersCount = parseInt(item.membersCount);
                    const status = `${membersCount} ${Translator.translate("MEMBERS", this.context.language)}`;
                    this.setState({ status: status });
                }
                break;
            case enums.TYPING_STARTED:
                this.onTypingStarted(item);
                break;
            case enums.TYPING_ENDED:
                this.onTypingEnded(item);
                break;
            default:
                break;
        }
    };

    toggleTooltip = (event, flag) => {
        const elem = event.target;
        const scrollWidth = elem.scrollWidth;
        const clientWidth = elem.clientWidth;

        if (scrollWidth <= clientWidth) {
            return false;
        }

        if (flag) {
            elem.setAttribute("title", elem.textContent);
        } else {
            elem.removeAttribute("title");
        }
    };

    enableUserPresence = () => {
        this.context.FeatureRestriction.isUserPresenceEnabled()
            .then(response => {
                if (response !== this.state.enableUserPresence) {
                    this.setState({ enableUserPresence: response });
                }
            })
            .catch(error => {
                if (this.state.enableUserPresence !== false) {
                    this.setState({ enableUserPresence: false });
                }
            });
    };

    close = () => {
        this.props.actionGenerated("CANCEL_SEND_FILES");
    }

    sendFiles = () => {
        this.props.actionGenerated("SEND_FILES", this.props.item, this.props.files);
    }

    render() {

        let avatar, presence;
        let chatWithClassName = "chat__user";
        let chatNameClassName = "user__name";

        if (this.context.type === CometChat.ACTION_TYPE.TYPE_USER) {
            avatar = <CometChatAvatar user={this.context.item} />;
            presence = <CometChatUserPresence status={this.state.presence} borderColor={this.props.theme.borderColor.primary} />;
        } else if (this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP) {
            chatWithClassName = "chat__group";
            chatNameClassName = "group__name";
            avatar = <CometChatAvatar group={this.context.item} />;
        }

        return <>
            <CometChatBackdrop show={true} clicked={this.close} />
            <div css={modalWrapperStyle(this.context)} className="modal__sendfile">
                <span css={modalCloseStyle(closeIcon, this.context)} className="modal__close" onClick={this.close} title={Translator.translate("CLOSE", this.context.language)}></span>
                <div css={modalBodyStyle()} className="modal__body">
                    <table css={modalTableStyle(this.props)}>
                        <caption css={tableCaptionStyle()} className="modal__title"> {Translator.translate("SEND_TO", this.context.language)} </caption>
                        <tbody css={tableBodyStyle()} className="modal__search">
                            <tr>
                                <td>
                                    <div css={chatDetailStyle()} className="chat__details">
                                        <div css={chatThumbnailStyle()} className="chat__thumbnail">
                                            {avatar}
                                            {presence}
                                        </div>
                                        <div css={chatUserStyle(this.context)} className={chatWithClassName}>
                                            <h6 css={chatNameStyle(this.context)} className={chatNameClassName} onMouseEnter={event => this.toggleTooltip(event, true)} onMouseLeave={event => this.toggleTooltip(event, false)}>
                                                {this.context.item.name}
                                            </h6>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div css={fileListStyle(this.context)} className="sender__files">
                                        {this.props.files.map(file => (
                                            <div key={file.name} css={fileStyle()} className="sender__file">{file.name}</div>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot css={tableFootStyle(this.context, this.state, creatingIcon)}>
                            <tr className="creategroup">
                                <td><button type="button" tabIndex="1" onClick={this.sendFiles}><span>{Translator.translate("SEND", this.context.language)}</span></button></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>;
    }
}

// Specifies the default values for props:
CometChatFileSender.defaultProps = {
    theme: theme,
    item: {},
    type: ""
};

CometChatFileSender.propTypes = {
    theme: PropTypes.object,
    item: PropTypes.object,
    type: PropTypes.string
}

export { CometChatFileSender };

