export default function testGroup(searchKeys: string[]) {
    const keys = searchKeys.map(key => key.toLowerCase())
    return (group: any) => {
        const searchFields = [
            group.name,
            ...group.members?.flatMap((member: any) => [
                member.name,
                ...(member.metadata.companyList?.flatMap((company: any) => [company.cbdsName, company.cbdsCode]) || []),
            ]) ?? [],
        ]
            .filter(Boolean)
            .map(field => field.toLowerCase())
        return keys.every(key => searchFields.some(field => field.includes(key)))
    }
}