export const modalRowStyle = (context) => {

    return {
        borderLeft: `1px solid ${context.theme.borderColor.primary}`,
        borderRight: `1px solid ${context.theme.borderColor.primary}`,
        borderBottom: `1px solid ${context.theme.borderColor.primary}`,
        display: "flex",
        width: "100%",
        fontSize: "14px",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "8px",
    }
}

export const nameColumnStyle = (context, participantView) => {

    const widthProp = (participantView) ? {
        width: "calc(100% - 180px)"
    } : {
        width: "calc(100% - 260px)"
    };

    return {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        ...widthProp
    }
}

export const avatarStyle = (context, participantView) => {

    const marginProp = (participantView) ? {
        marginRight: "8px",
    } : {
        marginRight: "8px",
    };

    return {
        width: "36px",
        height: "36px",
        flexShrink: "0",
        ...marginProp
    }
}

export const itemDetailStyle = () => {

    return {
        width: "calc(100% - 50px)",
        flexGrow: 1
    }
}

export const nameStyle = (context, participantView) => {

    const widthProp = (participantView) ? {
        width: "100%"
    } : {
        width: "calc(100% - 50px)"
    };

    const displayProp = (participantView) ? {
        display: "inline",
    } : {
        display: "inline",
    }

    return {
        margin: "8px 0",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        ...widthProp,
        ...displayProp
    }
}

export const descStyle = (context) => {

    return {
        color: `${context.theme.color.helpText}`,
        fontSize: '10px'
    }
}

export const scopeColumnStyle = (context) => {

    return {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "180px",
        "img": {
            width: "24px",
            height: "24px",
            cursor: "pointer",
        },
    }
}

export const scopeWrapperStyle = () => {

    return {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        transition: "opacity .1s linear",
        "img": {
            margin: "0px 4px",
        }
    }
}

export const scopeSelectionStyle = () => {

    return {
        width: "65%",
        border: "0",
        boxShadow: "rgba(20, 20, 20, 0.04) 0 0 0 1px inset",
        borderRadius: "8px",
        backgroundColor: `rgba(20, 20, 20, 0.04)`,
        padding: "8px",
        color: `rgba(20, 20, 20, 0.6)`,
        float: "left",
    }
}

export const scopeIconStyle = (img, context) => {

    return {
        width: "24px",
        height: "24px",
        display: "inline-block",
        cursor: "pointer",
        mask: `url(${img}) center center no-repeat`,
        backgroundColor: `${context.theme.secondaryTextColor}`,
    };
}

export const roleStyle = () => {

    return {
        fontSize: "12px",
        maxWidth: "calc(100% - 20px)"
    }
}

export const actionColumnStyle = (context) => {

    return {
        width: "70px",
    }
}

export const banIconStyle = (img, context) => {

    return {
        width: "24px",
        height: "24px",
        display: "inline-block",
        cursor: "pointer",
        mask: `url(${img}) center center no-repeat`,
        backgroundColor: `${context.theme.secondaryTextColor}`,
    };
}

export const kickIconStyle = (img, context) => {
    return {
        width: "24px",
        height: "24px",
        display: "inline-block",
        cursor: "pointer",
        background: `url(${img}) center center no-repeat`,
    };
};