
import { CometChat } from "@cometchat-pro/chat";
import PropTypes from "prop-types";
import { useContext } from "react";
import Translator from "../../../resources/localization/translator";
import { theme } from "../../../resources/theme";
import { CometChatContext } from "../../../util/CometChatContext";
import { CometChatAvatar } from "../../Shared";
import image from "./resources/new.svg";
import { itemDetailStyle, itemNameStyle, itemThumbnailStyle, listItem } from "./style";

const CometChatNewFriendItem = (props) => {

	const context = useContext(CometChatContext);

	const toggleTooltip = (event, flag) => {

		const elem = event.target;

		const scrollWidth = elem.scrollWidth;
		const clientWidth = elem.clientWidth;

		if (scrollWidth <= clientWidth) {
			return false;
		}

		if (flag) {
			elem.setAttribute("title", elem.textContent);
		} else {
			elem.removeAttribute("title");
		}
	}

	return (
		<div css={listItem(context)} onClick={() => props.clickHandler()} className="list__item">
			<div css={itemThumbnailStyle()} className="list__item__thumbnail">
				<CometChatAvatar image={image} />
			</div>
			<div css={itemDetailStyle()} className="list__item__details" dir={Translator.getDirection(context.language)}>
				<div css={itemNameStyle(context)} className="item__details__name"
					onMouseEnter={event => toggleTooltip(event, true)}
					onMouseLeave={event => toggleTooltip(event, false)}>{Translator.translate("FRIEND_REQUESTS")}</div>
			</div>
		</div>
	)
}

// Specifies the default values for props:
CometChatNewFriendItem.defaultProps = {
	theme: theme,
	user: {},
};

CometChatNewFriendItem.propTypes = {
	theme: PropTypes.object,
	user: PropTypes.shape(CometChat.User),
};

export { CometChatNewFriendItem };

