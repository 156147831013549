import { CometChat } from '@cometchat-pro/chat'
import { AxiosInstance } from 'axios'
import fetchGroupMembers from './fetchGroupMembers'

export default function fetchGroups(groupRequest: CometChat.GroupsRequest, axiosInstance: AxiosInstance) {
    return new Promise((resolve, reject) => {
        const allFetchGroupMembersPromises: Promise<any>[] = []
        function loop() {
            groupRequest.fetchNext()
                .then(groups => {
                    if (groups.length > 0) {
                        loop()
                        allFetchGroupMembersPromises.push(...groups.map(group => fetchGroupMembers(group, axiosInstance)))
                    } else {
                        Promise.all(allFetchGroupMembersPromises)
                            .then(resolve)
                            .catch(reject)
                    }
                })
                .catch(reject)
        }
        loop()
    })
}