import { CometChat } from "@cometchat-pro/chat";
import PropTypes from "prop-types";
import React from "react";
import { applicationActions } from '../../../../../layouts/Application/applicationSlice';
import errorToNotification from '../../../../../utils/axios/errorToNotification';
import testUser from '../../../../../utils/testUser';
import Translator from "../../../resources/localization/translator";
import { theme } from "../../../resources/theme";
import { CometChatContext, CometChatContextProvider } from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";
import { CometChatUserListItem } from "../../Users";
import { CometChatNewFriendItem } from '../CometChatNewFriendItem';
import { CometChatSendFriendRequest } from '../CometChatSendFriendRequest';
import { UserListManager } from "./controller";
import addIcon from "./resources/create.svg";
import searchIcon from "./resources/search.svg";
import structureIcon from "./resources/structure.svg";
import {
	contactAddStyle,
	contactAlphabetStyle, contactHeaderStyle, contactListStyle, contactMsgStyle,
	contactMsgTxtStyle, contactSearchButtonStyle,
	contactSearchInputStyle, contactSearchStyle, contactWrapperStyle, sortSwitchStyle
} from "./style";

class CometChatUserList extends React.PureComponent {

	item;
	static contextType = CometChatContext;

	constructor(props) {
		super(props);

		this.state = {
			searchKeys: [],
			userlist: [],
			sendFriendRequest: false,
			sortByCbds: false,
			enableSearchUser: false,
			decoratorMessage: Translator.translate("LOADING", props.lang),
		};

		this.contextProviderRef = React.createRef();

		CometChat.getLoggedinUser()
			.then(user => (this.loggedInUser = user))
			.catch(error => this.setState({ decoratorMessage: Translator.translate("SOMETHING_WRONG", this.props.lang) }));
	}

	componentDidMount() {

		this.item = (this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER) ? this.getContext().item : null;
		this.enableSearchUser();

		this.UserListManager = new UserListManager(this.getContext());
		this.getUsers();
		this.UserListManager.attachListeners(this.userUpdated);
	}

	componentDidUpdate(prevProps) {

		//if user is blocked/unblocked, update userlist
		if (this.item
			&& Object.keys(this.item).length
			&& this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER && this.item.uid === this.getContext().item.uid
			&& this.item.blockedByMe !== this.getContext().item.blockedByMe) {

			let userlist = [...this.state.userlist];

			//search for user
			let userKey = userlist.findIndex(u => u.uid === this.getContext().item.uid);
			if (userKey > -1) {

				let userObject = { ...userlist[userKey] };
				let newUserObject = Object.assign({}, userObject, { blockedByMe: this.getContext().item.blockedByMe });
				userlist.splice(userKey, 1, newUserObject);
				this.setState({ userlist: userlist });
			}
		}

		this.item = (this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER) ? this.getContext().item : null;
		this.enableSearchUser();
	}

	componentWillUnmount() {

		this.UserListManager.removeListeners();
		this.UserListManager = null;
	}

	enableSearchUser = () => {

		this.getContext()
			.FeatureRestriction.isUserSearchEnabled()
			.then(response => {
				/**
				* Don't update state if the response has the same value
				*/
				if (response !== this.state.enableSearchUser) {
					this.setState({ enableSearchUser: response });
				}

			}).catch(error => {

				if (this.state.enableSearchUser !== false) {
					this.setState({ enableSearchUser: false });
				}

			});
	}

	userUpdated = (user) => {

		let userlist = [...this.state.userlist];

		//search for user
		let userKey = userlist.findIndex(u => u.uid === user.uid);

		//if found in the list, update user object
		if (userKey > -1) {

			let userObj = { ...userlist[userKey] };
			let newUserObj = { ...userObj, ...user, metadata: userObj.metadata };
			userlist.splice(userKey, 1, newUserObj);

			this.setState({ userlist: userlist });
		}
	}

	handleClick = (user) => {

		if (!this.props.onItemClick)
			return;

		this.props.onItemClick(user, CometChat.ACTION_TYPE.TYPE_USER);
	}

	handleNewFriendRequestClick = () => {
		if (!this.props.onItemClick)
			return;

		this.props.onItemClick({ type: "friendrequest", rid: 'friendrequest', name: Translator.translate("FRIEND_REQUESTS", this.props.lang) }, 'friendrequest');
	}

	handleMenuClose = () => {

		if (!this.props.actionGenerated) {
			return false;
		}

		this.props.actionGenerated(enums.ACTIONS["TOGGLE_SIDEBAR"]);
	}

	sendFriendRequestHandler = (open) => {
		this.setState({ sendFriendRequest: open });
	}

	sendFriendActionHandler = (action, user) => {

	}

	sortSwitchHandler = () => {
		this.setState(s => ({ sortByCbds: !s.sortByCbds }))
	}

	updateSearchKeys = e => {
		this.setState({
			searchKeys: e.target.value.split(" ")
				.map(val => val.trim())
				.filter(Boolean)
		});
	}

	getUsers = () => {
		this.UserListManager.fetchUsers()
			.then(userList => {
				if (userList.length === 0) {
					if (this.state.userlist.length === 0) {
						this.setState({ decoratorMessage: Translator.translate("NO_USERS_FOUND", this.props.lang) });
					}
				} else {
					this.setState({ userlist: [...this.state.userlist, ...userList], decoratorMessage: "" });
				}

			})
			.catch(error => {
				this.context.dispatch(applicationActions.pushNotification(errorToNotification(error)))
				this.setState({ decoratorMessage: Translator.translate("SOMETHING_WRONG", this.props.lang) })
			});
	}

	getContext = () => {

		if (this.props._parent.length) {
			return this.context;
		} else {
			return this.contextProviderRef.state;
		}
	}

	render() {

		let messageContainer = null;
		if (this.state.decoratorMessage.length !== 0) {
			messageContainer = (
				<div css={contactMsgStyle()} className="contacts__decorator-message">
					<p css={contactMsgTxtStyle(theme)} className="decorator-message">
						{this.state.decoratorMessage}
					</p>
				</div>
			);
		}

		const userList = this.state.userlist.filter(testUser(this.state.searchKeys));
		let currentLetter = "";
		let users = []
		if (this.state.sortByCbds) {
			const map = userList.reduce((acc, user) => {
				if (user.metadata.cbdsUid) {
					if (!acc[user.metadata.cbdsUid]) {
						acc[user.metadata.cbdsUid] = {
							company: { cbdsName: user.metadata.cbdsName },
							users: []
						}
					}
					acc[user.metadata.cbdsUid].users.push(user)
				} else {
					if (!acc['']) {
						acc[''] = {
							company: { cbdsName: '' },
							users: []
						}
					}
					acc[''].users.push(user)
				}
				return acc
			}, {})
			users = Object.keys(map)
				.sort((a, b) => map[a].company.cbdsName.toLowerCase().localeCompare(map[b].company.cbdsName.toLowerCase()))
				.map(cbdsUid => {
					const { company: { cbdsName }, users } = map[cbdsUid]
					return <React.Fragment key={cbdsUid}>
						<div css={contactAlphabetStyle(this.props)} className="contacts__list__alphabet-filter">{cbdsName}</div>
						{users.map(user => {
							let selectedUser = (this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER && this.getContext().item.uid === user.uid) ? user : null;
							return <CometChatUserListItem display={this.state.sortByCbds} key={user.uid} user={user} selectedUser={selectedUser} clickHandler={this.handleClick} />
						})}
					</React.Fragment>
				});
		} else {
			users = userList.sort((a, b) => {
				let codePoint = 0;
				const namea = a.name.toUpperCase();
				const nameb = b.name.toUpperCase();
				// 255 is max length of user name
				while (codePoint < 255) {
					if (namea.length <= codePoint) return -1;
					if (nameb.length <= codePoint) return 1;
					const value = namea.codePointAt(codePoint) - nameb.codePointAt(codePoint);
					if (value !== 0) return value;
					codePoint++;
				}
				return 0;
			}).map(user => {
				const chr = user.name[0].toUpperCase();
				let firstChar = null;
				if (chr !== currentLetter) {
					currentLetter = chr;
					firstChar = (<div css={contactAlphabetStyle(this.props)} className="contacts__list__alphabet-filter">{currentLetter}</div>);
				} else {
					firstChar = null;
				}

				let selectedUser = (this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER && this.getContext().item.uid === user.uid) ? user : null;

				return (
					<React.Fragment key={user.uid}>
						{firstChar}
						<CometChatUserListItem display={this.state.sortByCbds} user={user} selectedUser={selectedUser} clickHandler={this.handleClick} />
					</React.Fragment>
				);
			});
		}

		let sendFriendRequestBtn = (
			<div css={contactAddStyle(addIcon, theme)}
				title={Translator.translate("SEND_FRIEND_REQUEST", this.props.lang)}
				onClick={() => this.sendFriendRequestHandler(true)}>
				<i></i>
			</div>
		);

		let switchSortBtn = (
			<div css={sortSwitchStyle(structureIcon, theme, this.state.sortByCbds)}
				title={this.state.sortByCbds ? Translator.translate("SORT_BY_USER", this.props.lang) : Translator.translate("SORT_BY_COMPANY", this.props.lang)}
				onClick={() => this.sortSwitchHandler()}>
				<i></i>
			</div>
		);

		if (this.state.enableSwitchSort === false) {
			switchSortBtn = null;
		}

		let sendFriendRequest = null;
		if (this.state.sendFriendRequest) {
			sendFriendRequest = <CometChatSendFriendRequest theme={this.props.theme} close={() => this.sendFriendRequestHandler(false)} actionGenerated={this.sendFriendActionHandler} />;
		}

		let searchUser = null;
		if (this.state.enableSearchUser) {
			searchUser = (
				<div css={contactSearchStyle()} className="contacts__search">
					<button type="button" className="search__button" css={contactSearchButtonStyle(searchIcon, theme)} />
					<input type="text" autoComplete="off" css={contactSearchInputStyle(this.props)} className="search__input" placeholder={Translator.translate("SEARCH", this.props.lang)} onChange={this.updateSearchKeys} />
				</div>
			);
		}

		const userListTemplate = (
			<>
				<div css={contactWrapperStyle(this.props, theme)} className="contacts">
					<div css={contactHeaderStyle(theme)} className="contacts__header">
						{searchUser}
						{switchSortBtn}
						{sendFriendRequestBtn}
					</div>
					{messageContainer}
					<div css={contactListStyle()} className="contacts__list">
						<CometChatNewFriendItem clickHandler={this.handleNewFriendRequestClick} />
						{users}
					</div>
				</div>
				{sendFriendRequest}
			</>
		);

		let userListWrapper = (userListTemplate);
		if (this.props._parent === "") {
			userListWrapper = (
				<CometChatContextProvider ref={el => (this.contextProviderRef = el)}>
					{userListTemplate}
				</CometChatContextProvider>
			);
		}

		return (userListWrapper);
	}
}


CometChatUserList.propTypes = {
	lang: PropTypes.string,
	theme: PropTypes.object,
	onItemClick: PropTypes.func,
	_parent: PropTypes.string
}

export { CometChatUserList };

