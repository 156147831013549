import { AxiosInstance } from 'axios'
import { ActiveFlag } from '../../enums/ActiveFlag'
import { CbdsType } from '../../enums/CbdsType'

interface CbdsInfoVo {
    cbdsUid: string,
    cbdsType: CbdsType,
    cbdsId: number,
    cbdsCode: string,
    cbdsName?: string,
    cbdsShortCode?: string,
    companyId?: number,
    regionCode?: string,
    address1?: string,
    address2?: string,
    address3?: string,
    address4?: string,
    contact1?: string,
    telephone1?: string,
    fax1?: string,
    email1?: string,
    contact2?: string,
    telephone2?: string,
    fax2?: string,
    email2?: string,
    postalCode?: string,
    calendarId?: number,
    timeZone?: string,
    activeFlag?: ActiveFlag,
}

const cbdsApi = {
    getActiveCompanysByActive: function (axiosInstance: AxiosInstance) {
        return axiosInstance.post<CbdsInfoVo[]>('/lcbm-system-master-api/api/getActiveCompanyByType', [CbdsType.Bu, CbdsType.Cust, CbdsType.Dc, CbdsType.Supp])
    },
    getAllCompanyByType: function (axiosInstance: AxiosInstance) {
        return axiosInstance.post<CbdsInfoVo[]>('/lcbm-system-master-api/api/getAllCompanyByType', [CbdsType.Bu, CbdsType.Cust, CbdsType.Dc, CbdsType.Supp])
    },
    getCompanyByUids: function (axiosInstance: AxiosInstance, cbdsUids: string[]) {
        return axiosInstance.post<CbdsInfoVo[]>('/lcbm-system-master-api/api/getCompanyByUids', cbdsUids)
    },
}

export default cbdsApi