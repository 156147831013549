import { createAction } from '@reduxjs/toolkit'
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { useSelector } from 'react-redux'
import appConfig from '../../configs/appConfig'
import { CbdsType } from '../../enums/CbdsType'
import { Notification } from '../../utils/Notification'

interface User {
    id: string,
    name: String,
    powerUser: boolean,
    activateChat: boolean,
    email: string,
    phone?: string,
    contactCode?: string,
    localCode?: string,
    account: UserAccount,
    cbdss: UserCbds[],
}

interface UserAccount {
    loginId: string,
    expireAt?: number,
    active: boolean,
    locked: boolean,
}

interface UserCbds {
    cbdsUid: string,
    defaultCbds: boolean,
    roleIds: string[],
    resourceIds: string[],
}

interface Role {
    id: string,
    name: string,
    description: string,
    resourceIds: string[],
}

enum ResourceType {
    QUERY = 'QUERY',
    ACTION = 'ACTION',
}

interface Resource {
    id: string,
    code: string,
    type: ResourceType
    category: string,
    module: string,
    action: string,
}

interface UserCompany {
    cbdsUid: string,
    cbdsType: CbdsType,
    cbdsId: CbdsType,
    cbdsCode: string,
    cbdsName: string,
    defaultFlag?: number,
}

interface UserSetting {
    daSetting: DaSetting,
}

interface DaSetting {
    ruleBasedRecommendation: boolean,
    aiPageRecommendation: boolean,
}

interface CodeCategory {
    codeCategoryId: number,
    codeCategory: string,
    language: string,
    codeValue: number,
    codeName: string,
}

export interface ApplicationState {
    auth: {
        token: string | null,
        companyUid: string | null,
        accessResources: string[] | null,
        user: User | null,
        roles: Role[] | null,
        resources: Resource[] | null,
        userCompanies: UserCompany[] | null,
    },
    i18n: {
        language: string,
        timezone: string,
        messages: Record<string, Record<string, string>>,
    }
    ui: {
        theme: string,
        maskCount: number,
        notifications: (Notification & { id: string | number, dismissed?: boolean })[],
        notificationHistories: (Notification & { id: string | number })[],
        notFound: {
            base: boolean,
            home: boolean,
            order: boolean,
            logistics: boolean,
            accounting: boolean,
            mt: boolean,
            dashboard: boolean,
            master: boolean,
            privilege: boolean,
            smt: boolean,
            todo: boolean,
        }
    },
    setting: {
        userSetting: UserSetting | null,
    }
    cache: {
        codeCategories: CodeCategory[] | null,
    },
    onlineChat: {
        visible: boolean,
        unreadMessageCount: number,
    },
}

export const applicationSlice = {
    name: 'application',
    actions: {
        setToken: createAction<string | null>('application/setToken'),
        setCompanyUid: createAction<string | null>('application/setCompanyUid'),
        setUser: createAction<User | null>('application/setUser'),
        setRoles: createAction<Role[] | null>('application/setRoles'),
        setResources: createAction<Resource[] | null>('application/setResources'),
        setUserCompanies: createAction<UserCompany[] | null>('application/setUserCompanies'),
        setLanguage: createAction<string>('application/setLanguage'),
        setTimezone: createAction<string>('application/setTimezone'),
        loadMessage: createAction<Record<string, Record<string, string>>>('application/loadMessage'),
        setTheme: createAction<string>('application/setTheme'),
        addMask: createAction<void>('application/addMask'),
        removeMask: createAction<void>('application/removeMask'),
        pushNotification: createAction<Notification>('application/pushNotification'),
        pushInfo: createAction<Omit<Notification, 'type'>>('application/pushInfo'),
        pushSuccess: createAction<Omit<Notification, 'type'>>('application/pushSuccess'),
        pushWarning: createAction<Omit<Notification, 'type'>>('application/pushWarning'),
        pushError: createAction<Omit<Notification, 'type'>>('application/pushError'),
        closeNotification: createAction<string | number>('application/closeNotification'),
        removeNotification: createAction<string | number>('application/removeNotification'),
        pruningNotificationHistories: createAction<number>('application/pruningNotificationHistories'),
        notFound: createAction<{ scope: keyof ApplicationState['ui']['notFound'], notFound: boolean }>('application/notFound'),
        setUserSetting: createAction<UserSetting>('application/setUserSetting'),
        setCodeCategories: createAction<CodeCategory[] | null>('application/setCodeCategories'),
        toggleOnlineChat: createAction<void>('application/toggleOnlineChat'),
        setUnreadMessageCount: createAction<number>('application/setUnreadMessageCount'),
        logout: createAction<void>('application/logout'),
    }
}

export const applicationActions = applicationSlice.actions

export function useApplicationSelector<R>(selector: (state: ApplicationState) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => {
        return selector(state[appConfig.baseFullName][applicationSlice.name])
    }, equalityFn)
}

export function thunkApplicationSelector<R>(thunk: BaseThunkAPI<any, any, any, any, any, any>, selector: (state: ApplicationState) => R) {
    return selector(thunk.getState()[appConfig.baseFullName][applicationSlice.name])
}