import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useOnlineChatSelector } from '../OnlineChat/onlineChatSlice'
import { applicationActions } from './applicationSlice'

export function ApplicationProxy() {
    const unreadMessageCount = useOnlineChatSelector(state => state.unreadMessageCount)
    const newFriendRequestCount = useOnlineChatSelector(state => state.newFriendRequestCount)
    const notificationCount = unreadMessageCount + newFriendRequestCount
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(applicationActions.setUnreadMessageCount(notificationCount))
    }, [dispatch, notificationCount])
    return null
}