export const unifiedStyle = (props) => {

    return {
        display: "flex",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        fontFamily: `${props.theme.fontFamily}`,
        position: "relative",
        "*": {
            boxSizing: "border-box",
            fontFamily: `${props.theme.fontFamily}`,
            "::-webkit-scrollbar": {
                width: "8px",
                height: "4px",
            },
            "::-webkit-scrollbar-track": {
                background: "#ffffff00"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#ccc",
                "&:hover": {
                    background: "#aaa"
                }
            }
        }
    }
}

export const unifiedSidebarStyle = (props) => {

    return {
        width: "400px",
        borderRight: `1px solid ${props.theme.borderColor.primary}`,
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        "> .contacts, .chats, .groups, .userinfo": {
            height: "calc(100% - 64px)",
        }
    }
}

export const unifiedMainStyle = (props) => {

    return {
        width: "calc(100% - 400px)",
        height: "100%",
        order: "2",
        display: "flex",
        flexDirection: "row"
    }
}
