import { CometChat } from '@cometchat-pro/chat'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import appConfig from '../../configs/appConfig'
import { FriendRequestStatus } from '../../enums/FriendRequestStatus'
import friendRequestApi from '../../services/onlineChat/friendRequestApi'
import errorToNotification from '../../utils/axios/errorToNotification'
import thunkAxiosInstance from '../../utils/axios/thunkAxiosInstance'
import { applicationActions, thunkApplicationSelector } from '../Application/applicationSlice'

interface OnlineChatState {
    fullScreen: boolean,
    unreadMessageCount: number,
    newFriendRequestCount: number,
}

const initialState: OnlineChatState = {
    fullScreen: false,
    unreadMessageCount: 0,
    newFriendRequestCount: 0,
}

const updateUnreadMessageCount = createAsyncThunk<void>('onlineChat/unpdateUnreadMessageCount', (_, thunk) => {
    return CometChat.getUnreadMessageCount(true)
        .then(response => {
            const users: Record<string, number> = (response as any).users
            const groups: Record<string, number> = (response as any).groups
            const count = Object.values(users).reduce((a, c) => a + c, 0) + Object.values(groups).reduce((a, c) => a + c, 0)
            thunk.dispatch(onlineChatActions.setUnreadMessageCount(count))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const updateNewFriendRequestCount = createAsyncThunk<void>('onlineChat/updateNewFriendRequestCount', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const loginId = thunkApplicationSelector(thunk, state => state.auth.user!.account.loginId)
    const cbdsUid = thunkApplicationSelector(thunk, state => state.auth.companyUid ?? undefined)
    return friendRequestApi.list(axiosInstance, { receiverLoginId: loginId, receiverCompany: cbdsUid, status: FriendRequestStatus.PENDING })
        .then(response => {
            const count = response.data.friendRequests.length
            thunk.dispatch(onlineChatActions.setNewFriendRequestCount(count))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

export const onlineChatSlice = createSlice({
    name: 'onlineChat',
    initialState,
    reducers: {
        toggleFullScreen: (state) => {
            state.fullScreen = !state.fullScreen
        },
        setUnreadMessageCount(state, action: { payload: number }) {
            state.unreadMessageCount = action.payload
        },
        setNewFriendRequestCount(state, action: { payload: number }) {
            state.newFriendRequestCount = action.payload
        }
    },
    extraReducers: {
    }
})

export const onlineChatActions = {
    ...onlineChatSlice.actions,
    updateUnreadMessageCount,
    updateNewFriendRequestCount,
}

export function useOnlineChatSelector<R>(selector: (state: OnlineChatState) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(state[appConfig.appFullName][onlineChatSlice.name]), equalityFn)
}