import { AxiosInstance } from 'axios'
import { PageResultEntity } from '../../utils/PageResultEntity'

interface UserAPIResponse {
    id: string,
    name: string,
    powerUser: boolean,
    activateChat: boolean,
    email: string,
    phone?: string,
    contactCode?: string,
    localCode?: string,
    account: UserAccount,
    cbdss: UserCbds[],
}

interface UserAccount {
    loginId: string,
    expireAt?: number,
    active: boolean,
    locked: boolean,
}

interface UserCbds {
    cbdsUid: string,
    defaultCbds: boolean,
    roleIds: string[],
    resourceIds: string[],
}

interface ListUsersAPIRequest {
    nameContains?: string,
    nameIn?: string[],
    powerUser?: boolean,
    activateChat?: boolean,
    emailContains?: string,
    emailIn?: string[],
    phoneContains?: string,
    phoneIn?: string[],
    contactCodeContains?: string,
    contactCodeIn?: string[],
    localCodeContains?: string,
    localCodeIn?: string[],
    loginIdContains?: string,
    loginIdIn?: string[],
    expireAtBefore?: number,
    expireAtAfter?: number,
    expireAtSince?: number,
    expireAtUntil?: number,
    active?: boolean,
    locked?: boolean,
    cbdsUidIn?: string[],
    defaultCbdsUidIn?: string[],
    roleIdIn?: string[],
    roleNameIn?: string[],
    resourceIdIn?: string[],
    resourceNameIn?: string[],
    currentPage?: number,
    pageSize?: number,
}

const userApi = {
    list: function (axiosInstance: AxiosInstance, data: ListUsersAPIRequest) {
        return axiosInstance.get<PageResultEntity<UserAPIResponse>>('/common-system-api/users', { params: data })
    },
}

export default userApi