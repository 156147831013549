import { useDispatch } from 'react-redux'
import { useApplicationSelector } from '../../layouts/Application/applicationSlice'
import shareAxiosInstance from './shareAxiosInstance'

/**
 * As this will used in `useEffect` hook or `useCallback` hook, we should make sure at least
 * one `useAxiosInstance` hook always returns the same instance. Otherwise, the instance change
 * will cause the dependency change, which will cause the `useEffect` hook to re-run and 
 * `useCallback` hook to re-render.
 * 
 * @returns a stable axios instance
 */
export default function useAxiosInstance() {
    const dispatch = useDispatch()
    const options = useApplicationSelector(state => ({
        token: state.auth.token,
        companyUid: state.auth.companyUid,
        language: state.i18n.language,
    }), (left, right) => {
        return left.token === right.token &&
            left.companyUid === right.companyUid &&
            left.language === right.language
    })

    // share a single axios instance
    return shareAxiosInstance(dispatch, options)
}