import { CometChat } from "@cometchat-pro/chat";

export const modalWrapperStyle = (context) => {

    return {
        minWidth: "400px",
        minHeight: "276px",
        width: "40%",
        height: "40%",
        overflow: "hidden",
        backgroundColor: `${context.theme.backgroundColor.white}`,
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "4",
        margin: "0 auto",
        boxShadow: "rgba(20, 20, 20, 0.2) 0 16px 32px, rgba(20, 20, 20, 0.04) 0 0 0 1px",
        borderRadius: "12px",
        display: "block"
    }
}

export const modalCloseStyle = (img, context) => {

    return {
        position: "absolute",
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        top: "16px",
        right: "16px",
        mask: `url(${img}) center center no-repeat`,
        backgroundColor: `${context.theme.primaryColor}`,
        cursor: "pointer",
    }
}

export const modalBodyStyle = () => {

    return {
        padding: "24px",
        height: "100%",
        width: "100%"
    }
}


export const modalTableStyle = (props) => {

    return {
        borderCollapse: "collapse",
        margin: "0",
        padding: "0",
        width: "100%",
        height: "90%",
        "tr": {
            display: "table",
            width: "100%",
            tableLayout: "fixed",
        }
    }
}

export const tableCaptionStyle = () => {

    return {
        fontSize: "20px",
        marginBottom: "15px",
        fontWeight: "bold",
        textAlign: "left",
    }
}

export const tableBodyStyle = () => {

    return {
        height: "calc(100% - 40px)",
        overflowY: "auto",
        display: "block",
        "tr": {
            "td": {
                padding: "8px 0",
                fontSize: "14px",
                "input": {
                    width: "100%",
                    border: "none",
                    padding: "8px 16px",
                    fontSize: "14px",
                    outline: "none",
                },
                "select": {
                    outline: "none",
                    padding: "8px 16px",
                }
            }
        }
    }
}

export const tableFootStyle = (context, state, img) => {

    const loadingState = (state.creatingGroup) ? {
        disabled: "true",
        pointerEvents: "none",
        background: `url(${img}) no-repeat right 10px center ${context.theme.primaryColor}`,
    } : {};

    const textMargin = (state.creatingGroup) ? { marginRight: "24px", } : {};

    return {
        display: "inline-block",
        "button": {
            cursor: "pointer",
            padding: "8px 16px",
            backgroundColor: `${context.theme.primaryColor}`,
            borderRadius: "5px",
            color: `${context.theme.color.white}`,
            fontSize: "14px",
            outline: "0",
            border: "0",
            ...loadingState,
            "span": {
                ...textMargin
            }
        },
        "tr": {
            border: "none",
            "td": {
                textAlign: "center",
            }
        },
    }
}

export const fileListStyle = context => {
    return {
        width: "100%",
        height: "90px",
        backgroundColor: context.theme.backgroundColor.grey,
        border: `1px solid ${context.theme.borderColor.primary}`,
        borderRadius: "8px",
        padding: "4px 8px",
        overflow: "auto",
    }
}

export const fileStyle = () => {
    return {
        margin: "4px 0px",
    }
}

export const chatDetailStyle = () => {

    return {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "calc(100% - 116px)",
    }
}

export const chatThumbnailStyle = () => {

    return {
        display: "inline-block",
        width: "36px",
        height: "36px",
        flexShrink: "0",
        margin: "0 16px",
    }
}

export const chatUserStyle = context => {

    return {
        width: "calc(100% - 50px)",
        padding: "0",
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
    };
};

export const chatNameStyle = (context) => {

    return {
        margin: "0",
        fontSize: "15px",
        fontWeight: "600",
        lineHeight: "22px",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: `${context.theme.primary}`,
    }
}

export const chatStatusStyle = (state, context) => {

    let status = {};
    if (context.type === CometChat.ACTION_TYPE.TYPE_USER) {

        status = {
            color: `${context.theme.color.blue}`,
            textTransform: "capitalize",
        };

        if (state.presence === "offline") {
            status = {
                color: `${context.theme.color.helpText}`,
                textTransform: "capitalize",
            }
        }

        if (state.typing) {
            status = {
                color: `${context.theme.color.helpText}`,
                textTransform: "none",
                fontStyle: "italic",
            };
        }

    } else if (context.type === CometChat.ACTION_TYPE.TYPE_GROUP) {

        status = {
            color: `${context.theme.color.helpText}`,
        }

        if (state.typing) {
            status = {
                color: `${context.theme.color.helpText}`,
                fontStyle: "italic",
            };
        }
    }

    return {
        fontSize: "13px",
        width: "100%",
        ...status
    }
}

export const chatOptionWrapStyle = () => {

    return {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "auto",
    }
}

export const chatOptionStyle = (img, context, ongoingCall) => {

    const bgColor = (ongoingCall) ? {
        backgroundColor: `${context.theme.secondaryTextColor}`
    } : {
        backgroundColor: `${context.theme.primaryColor}`
    };

    return {
        width: "24px",
        height: "24px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        margin: "0 0 0 16px",
        "i": {
            width: "24px",
            height: "24px",
            display: "inline-block",
            mask: `url(${img}) center center no-repeat`,
            ...bgColor
        }
    }
}
