import { CometChat } from "@cometchat-pro/chat"
import { Grow, makeStyles, Paper, Popper } from '@material-ui/core'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import appConfig from '../../configs/appConfig'
import userApi from '../../services/onlineChat/userApi'
import errorToNotification from '../../utils/axios/errorToNotification'
import useAxiosInstance from '../../utils/axios/useAxiosInstance'
import { applicationActions, useApplicationSelector } from '../Application/applicationSlice'
import { OnlineChat } from './OnlineChat'
import { onlineChatActions, useOnlineChatSelector } from './onlineChatSlice'

export function OnlineChatContainer() {
    const ready = useReady()
    const visible = useApplicationSelector(state => state.onlineChat.visible)
    const fullScreen = useOnlineChatSelector(state => state.fullScreen)
    const ref = useRef<HTMLDivElement>(null)

    const classes = useStyles()
    return <div className={clsx(classes.container)}>
        <div ref={ref} className={clsx(classes.anchor)}></div>
        <Popper
            transition
            keepMounted
            disablePortal
            open={ready && visible}
            anchorEl={ref.current}
            placement="bottom-end"
        >
            {({ TransitionProps }) => <Grow
                {...TransitionProps}
                style={{ transformOrigin: 'top right' }}
                onExited={() => {/* This is required to prevent a bug: style of div role="tooltip" will be empty when transaction exited. */ }}
            >
                <div className={clsx(classes.animate, fullScreen && classes.animateFullscreen)}>
                    <Paper className={clsx(classes.paper, fullScreen && classes.paperFullscreen)}>
                        {ready ? <OnlineChat /> : <div>Loading...</div>}
                    </Paper>
                </div>
            </Grow>}
        </Popper>
    </div>
}

const useStyles = makeStyles(theme => ({
    container: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
    },
    anchor: {
        position: 'absolute',
        top: 88,
        right: 96,
        width: 0,
        height: 0,
    },
    animate: {
        width: 900,
        height: 500,
    },
    animateFullscreen: {
        width: 'calc(100vw - 192px)',
        height: 'calc(100vh - 100px)',
    },
    paper: {
        position: 'absolute',
        right: 0,
        width: 900,
        height: 500,
        transition: `width ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}, height ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
        pointerEvents: 'auto',
    },
    paperFullscreen: {
        width: 'calc(100vw - 192px)',
        height: 'calc(100vh - 100px)',
    },
}))

function useReady() {
    const [ready, setReady] = useState(false)
    const hasToken = useApplicationSelector(state => !!state.auth.token)
    const currentCompanyUid = useApplicationSelector(state => state.auth.companyUid)
    const user = useApplicationSelector(state => state.auth.user)
    const [authToken, setAuthToken] = useState<String | undefined>(undefined)
    const dispatch = useDispatch()

    // initialize comet chat
    useEffect(() => {
        const appSetting = new CometChat.AppSettingsBuilder()
            .subscribePresenceForAllUsers()
            .setRegion(appConfig.region)
            .build()
        CometChat.init(appConfig.appId, appSetting).then(() => {
            CometChat.setSource && CometChat.setSource("ui-kit", "web", "reactjs")
        })
    }, [])

    // fetch authToken
    const axiosInstance = useAxiosInstance()
    useEffect(() => {
        if (hasToken && currentCompanyUid && user && user.activateChat) {
            userApi.get(axiosInstance)
                .then(response => setAuthToken(response.data.authToken))
                .catch(error => dispatch(applicationActions.pushNotification(errorToNotification(error))))
        }
    }, [axiosInstance, dispatch, hasToken, currentCompanyUid, user])

    // login 
    useEffect(() => {
        if (hasToken && authToken && !ready) {
            CometChat.login(authToken)
                .then(() => {
                    setReady(true)
                })
                .catch(error => dispatch(applicationActions.pushNotification(errorToNotification(error))))
        }
    }, [authToken, dispatch, hasToken, ready])

    // logout
    useEffect(() => {
        //if (hasToken && authToken) {
        //    CometChat.login(authToken)
        //        .then(() => setReady(true))
        //        .catch(error => dispatch(applicationActions.pushNotification(errorToNotification(error))))
        //}
        return () => {
            if (hasToken && authToken) {
                CometChat.logout()
                    .then(() => {
                        setReady(false)
                    })
                    .catch(error => dispatch(applicationActions.pushNotification(errorToNotification(error))))
            }
        }
    }, [authToken, dispatch, hasToken])

    // update unread messages
    useEffect(() => {
        const listenerId = "unread_" + new Date().getTime()
        function update() {
            ready && dispatch(onlineChatActions.updateUnreadMessageCount())
        }
        const listener = new CometChat.MessageListener({
            onTextMessageReceived: update,
            onMediaMessageReceived: update,
            onCustomMessageReceived: update,
            onTransientMessageReceived: update,
        })
        CometChat.addMessageListener(listenerId, listener)
        const intervalId = setInterval(update, 30000)
        update()

        return () => {
            CometChat.removeMessageListener(listenerId)
            clearInterval(intervalId)
        }
    }, [dispatch, ready])

    // update new friend requests
    useEffect(() => {
        function update() {
            dispatch(onlineChatActions.updateNewFriendRequestCount())
        }
        let intervalId: any = null
        if (ready && user && user.activateChat) {
            intervalId = setInterval(update, 60000)
            update()
        }
        return () => {
            if (ready && user && user.activateChat) {
                clearInterval(intervalId)
            }
        }
    }, [dispatch, ready, user])

    return ready
}

