export const modalRowStyle = (theme) => {

    return {
        border: `1px solid ${theme.borderColor.primary}`,
        display: "flex",
        width: "100%",
        fontSize: "14px",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        "&:not(:last-child)": {
            borderBottom: "none"
        }
    }
}

export const modalColumnStyle = () => {

    return {
        padding: "8px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        alignItems: "center",
    }
}

export const avatarStyle = () => {

    return {
        display: "inline-block",
        float: "left",
        width: "36px",
        height: "36px",
        //marginRight: "8px",
    }
}

export const itemDetailStyle = () => {

    return {
        width: "calc(100% - 45px)",
        flexGrow: 1,
        paddingLeft: "10px"
    }
}

export const nameStyle = () => {

    return {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        lineHeight: "22px",
    }
}

export const descStyle = (theme) => {

    return {
        color: `${theme.color.helpText}`,
        fontSize: '12px'
    }
}

export const selectionColumnStyle = () => {

    return {
        padding: "8px",
        width: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}

export const selectionBoxStyle = (inactiveStateImg, activeStateImg, theme) => {

    return {
        display: "none",
        " + label": {
            display: "block",
            cursor: "pointer",
            userSelect: "none",
            padding: "8px",
            width: "100%",
            mask: `url(${inactiveStateImg}) center center no-repeat`,
            backgroundColor: `${theme.secondaryTextColor}`,
        },
        "&:checked + label": {
            width: "100%",
            mask: `url(${activeStateImg}) center center no-repeat`,
            backgroundColor: `${theme.secondaryTextColor}`,
        }
    }
}