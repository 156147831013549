import { CometChat } from "@cometchat-pro/chat";
import PropTypes from "prop-types";
import React from "react";
import { CometChatLinkPreview, CometChatReadReceipt, CometChatThreadedMessageReplyCount } from "..";
import { FriendRequestStatus } from '../../../../../enums/FriendRequestStatus';
import { applicationActions } from '../../../../../layouts/Application/applicationSlice';
import friendRequestApi from '../../../../../services/onlineChat/friendRequestApi';
import errorToNotification from "../../../../../utils/axios/errorToNotification";
import shareAxiosInstance from '../../../../../utils/axios/shareAxiosInstance';
import Translator from "../../../resources/localization/translator";
import { theme } from "../../../resources/theme";
import { CometChatContext } from "../../../util/CometChatContext";
import { checkMessageForExtensionsData } from "../../../util/common";
import * as enums from "../../../util/enums.js";
import { CometChatAvatar } from "../../Shared";
import { CometChatMessageReactions } from "../Extensions";
import {
	actionStyle,
	messageContainerStyle, messageDetailStyle, messageInfoWrapperStyle,
	messageReactionsWrapperStyle, messageStyle, messageThumbnailStyle, messageTxtContainerStyle, messageTxtWrapperStyle, messageWrapperStyle, nameStyle, nameWrapperStyle, statusStyle, wrapperStyle
} from "./style";

class CometChatReceiverFriendRequestMessageBubble extends React.Component {
	static contextType = CometChatContext;

	constructor(props) {
		super(props);

		this.state = {
			translatedMessage: "",
			isHovering: false,
			enableLargerSizeEmojis: false,
			status: props.message.customData.status,
		};
	}

	shouldComponentUpdate(nextProps, nextState) {

		const currentMessageStr = JSON.stringify(this.props.message);
		const nextMessageStr = JSON.stringify(nextProps.message);

		if (currentMessageStr !== nextMessageStr
			|| this.state.isHovering !== nextState.isHovering
			|| this.state.translatedMessage !== nextState.translatedMessage
			|| this.state.enableLargerSizeEmojis !== nextState.enableLargerSizeEmojis) {
			return true;
		}
		return false;
	}

	componentDidMount() {
		this.enableLargerSizeEmojis();
	}

	componentDidUpdate(prevProps) {
		const previousMessageStr = JSON.stringify(prevProps.message);
		const currentMessageStr = JSON.stringify(this.props.message);

		if (previousMessageStr !== currentMessageStr) {
			this.setState({ translatedMessage: "" });
		}

		this.enableLargerSizeEmojis();
	}

	accept = () => {
		const axiosInstance = shareAxiosInstance(this.context.dispatch, this.context.options)
		const friendRequestId = this.props.message.customData.requestId;
		friendRequestApi.accept(axiosInstance, { friendRequestId })
			.then(() => {
				this.setState({ status: FriendRequestStatus.ACCEPTED })
			})
			.catch(error => this.context.dispatch(applicationActions.pushNotification(errorToNotification(error))));
	}

	reject = () => {
		const axiosInstance = shareAxiosInstance(this.context.dispatch, this.context.options)
		const friendRequestId = this.props.message.customData.requestId;
		friendRequestApi.reject(axiosInstance, { friendRequestId })
			.then(() => {
				this.setState({ status: FriendRequestStatus.REJECTED })
			})
			.catch(error => this.context.dispatch(applicationActions.pushNotification(errorToNotification(error))));
	}

	getMessageText = () => {
		const request = this.props.message.customData.request;
		const status = this.state.status;
		let actions = null;
		if (status === FriendRequestStatus.PENDING) {
			actions = <>
				<button css={actionStyle(this.context)} onClick={() => this.accept()}>
					{Translator.translate('ACCEPT', this.context.lang)}
				</button>
				<button css={actionStyle(this.context)} onClick={() => this.reject()}>
					{Translator.translate('DECLINE', this.context.lang)}
				</button>
			</>
		} else if (status === FriendRequestStatus.ACCEPTED) {
			actions = <div css={statusStyle(this.context)}>
				{Translator.translate('ACCEPTED', this.context.lang)}
			</div>
		} else if (status === FriendRequestStatus.REJECTED) {
			actions = <div css={statusStyle(this.context)}>
				{Translator.translate('DECLINED', this.context.lang)}
			</div>
		}

		return <div css={messageTxtWrapperStyle(this.context)} className="message__txt__wrapper">
			<div css={wrapperStyle()}>
				<div css={messageStyle()}>{request}</div>
				{actions}
			</div>
		</div>;
	};

	translateMessage = message => {
		const messageId = message.id;
		const messageText = message.text;

		const browserLanguageCode = Translator.getBrowserLanguage().toLowerCase();
		let translateToLanguage = browserLanguageCode;
		if (browserLanguageCode.indexOf("-") !== -1) {
			const browserLanguageArray = browserLanguageCode.split("-");
			translateToLanguage = browserLanguageArray[0];
		}

		let translatedMessage = "";
		CometChat.callExtension("message-translation", "POST", "v2/translate", {
			msgId: messageId,
			text: messageText,
			languages: [translateToLanguage],
		})
			.then(result => {
				if (result && result.hasOwnProperty("language_original") && result["language_original"] !== translateToLanguage) {
					if (result.hasOwnProperty("translations") && result.translations.length) {
						const messageTranslation = result.translations[0];
						if (messageTranslation.hasOwnProperty("message_translated")) {
							translatedMessage = `\n(${messageTranslation["message_translated"]})`;
						}
					} else {
						this.props.actionGenerated(enums.ACTIONS["ERROR"], [], "SOMETHING_WRONG");
					}
				} else {
					this.props.actionGenerated(enums.ACTIONS["INFO"], [], "SAME_LANGUAGE_MESSAGE");
				}

				this.setState({ translatedMessage: translatedMessage });
			})
			.catch(error => this.props.actionGenerated(enums.ACTIONS["ERROR"], [], "SOMETHING_WRONG"));
	};

	enableLargerSizeEmojis = () => {
		this.context.FeatureRestriction.isLargerSizeEmojisEnabled()
			.then(response => {
				if (response !== this.state.enableLargerSizeEmojis) {
					this.setState({ enableLargerSizeEmojis: response });
				}
			})
			.catch(error => {
				if (this.state.enableLargerSizeEmojis !== false) {
					this.setState({ enableLargerSizeEmojis: false });
				}
			});
	};

	handleMouseHover = () => {
		this.setState(this.toggleHoverState);
	};

	toggleHoverState = state => {
		return {
			isHovering: !state.isHovering,
		};
	};

	actionHandler = (action, message) => {
		switch (action) {
			case enums.ACTIONS["REACT_TO_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["VIEW_THREADED_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["DELETE_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["EDIT_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["TRANSLATE_MESSAGE"]:
				this.translateMessage(message);
				break;
			default:
				break;
		}
	};

	render() {

		let avatar = null,
			name = null;
		if (this.props.message.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
			avatar = (
				<div css={messageThumbnailStyle()} className="message__thumbnail">
					<CometChatAvatar user={this.props.message.sender} />
				</div>
			);

			name = (
				<div css={nameWrapperStyle(avatar)} className="message__name__wrapper">
					<span css={nameStyle(this.context)} className="message__name">
						{this.props.message.sender.name}
					</span>
				</div>
			);
		}
		if (this.context.type === enums.CUSTOM_TYPE_FRIEND_REQUEST) {
			avatar = (
				<div css={messageThumbnailStyle()} className="message__thumbnail">
					<CometChatAvatar user={this.props.message.sender} />
				</div>
			);

			name = (
				<div css={nameWrapperStyle(avatar)} className="message__name__wrapper">
					<span css={nameStyle(this.context)} className="message__name">
						{`${this.props.message.sender.name} ${this.props.message.sender.metadata.cbdsName ? `(${this.props.message.sender.metadata.cbdsName})` : ""}`}
					</span>
				</div>
			);
		}

		let messageText = this.getMessageText();
		//linkpreview extensions data
		const linkPreviewData = checkMessageForExtensionsData(this.props.message, "link-preview");
		if (linkPreviewData && linkPreviewData.hasOwnProperty("links") && linkPreviewData["links"].length) {
			messageText = <CometChatLinkPreview message={this.props.message} messageText={messageText} />;
		}

		//messagereactions extensions data
		let messageReactions = null;
		const reactionsData = checkMessageForExtensionsData(this.props.message, "reactions");
		if (reactionsData) {
			if (Object.keys(reactionsData).length) {
				messageReactions = (
					<div css={messageReactionsWrapperStyle()} className="message__reaction__wrapper">
						<CometChatMessageReactions message={this.props.message} actionGenerated={this.props.actionGenerated} />
					</div>
				);
			}
		}

		let toolTipView = null;
		// if (this.state.isHovering) {
		// 	toolTipView = <CometChatMessageActions message={this.props.message} actionGenerated={this.actionHandler} />;
		// }

		return (
			<div css={messageContainerStyle()} className="receiver__message__container message__text" onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseHover}>
				<div css={messageWrapperStyle()} className="message__wrapper">
					{avatar}
					<div css={messageDetailStyle()} className="message__details">
						{name}
						{toolTipView}
						<div css={messageTxtContainerStyle()} className="message__text__container">
							{messageText}
						</div>

						{messageReactions}

						<div css={messageInfoWrapperStyle()} className="message__info__wrapper">
							<CometChatReadReceipt message={this.props.message} />
							<CometChatThreadedMessageReplyCount message={this.props.message} actionGenerated={this.props.actionGenerated} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// Specifies the default values for props:
CometChatReceiverFriendRequestMessageBubble.defaultProps = {
	theme: theme,
	actionGenerated: () => { },
};

CometChatReceiverFriendRequestMessageBubble.propTypes = {
	theme: PropTypes.object,
	actionGenerated: PropTypes.func.isRequired,
	message: PropTypes.object.isRequired,
};

export { CometChatReceiverFriendRequestMessageBubble };

