export default function testUser(searchKeys: string[]) {
    const keys = searchKeys.map(key => key.toLowerCase())
    return (user: any) => {
        const searchFields = [
            user.name,
            ...(user.metadata.companyList?.flatMap((company: any) => [company.cbdsName, company.cbdsCode]) || []),
        ]
            .filter(Boolean)
            .map(field => field.toLowerCase())
        return keys.every(key => searchFields.some(field => field.includes(key)))
    }
}