import testGroup from './testGroup'
import testUser from './testUser'

export default function testChat(searchKeys: string[]) {
    const testGroupFn = testGroup(searchKeys)
    const testUserFn = testUser(searchKeys)
    return (chat: any) => {
        if (chat.conversationType === 'group') {
            return testGroupFn(chat.conversationWith)
        } else if (chat.conversationType === 'user') {
            return testUserFn(chat.conversationWith)
        } else {
            return true
        }
    }
}