import { CometChat } from '@cometchat-pro/chat'
import { AxiosInstance } from 'axios'
import fetchUsersMetadata from './fetchUsersMetadata'

export default function fetchBannedGroupMembers(group: any, axiosInstance: AxiosInstance) {
    const groupMemberRequest = new CometChat.BannedMembersRequestBuilder(group.guid)
        .setLimit(30)
        .build()
    return new Promise((resolve, reject) => {
        const allFetchUserMetadataPromises: Promise<any[]>[] = []
        function loop() {
            groupMemberRequest.fetchNext()
                .then(members => {
                    if (members.length > 0) {
                        loop()
                        allFetchUserMetadataPromises.push(fetchUsersMetadata(members, axiosInstance))
                    } else {
                        Promise.all(allFetchUserMetadataPromises)
                            .then(members => resolve(members.flat()))
                            .catch(reject)
                    }
                })
                .catch(reject)
        }
        loop()
    })
}