import { AxiosInstance } from 'axios'
import { FriendRequestStatus } from '../../enums/FriendRequestStatus'

interface FriendRequestAPIResponse {
    id: string,
    senderLoginId: string,
    receiverLoginId: string,
    senderCompany: string,
    receiverCompany: string,
    request?: string,
    requestTime: number,
    status: FriendRequestStatus,
}

interface ListFriendRequestsAPIRequest {
    senderLoginId?: string,
    receiverLoginId?: string,
    senderCompany?: string,
    receiverCompany?: string,
    status?: FriendRequestStatus,
    currentPage?: number,
    perPage?: number,
}

interface ListFriendRequestsAPIResponse {
    friendRequests: FriendRequestAPIResponse[],
    pagination: {
        totalCount: number,
        currentCount: number,
        totalPages: number,
        currentPage: number,
        perPage: number,
    }
}

interface CreateFriendRequestAPIRequest {
    senderLoginId: string,
    receiverLoginId: string,
    senderCompany: string,
    receiverCompany: string,
    request?: string,
}

const friendRequestApi = {
    get: function (axiosInstance: AxiosInstance, data: { friendRequestId: string }) {
        return axiosInstance.get<FriendRequestAPIResponse>('/common-online-chat-api/friend-requests/:friendRequestId', { pathVars: { friendRequestId: data.friendRequestId } })
    },
    list: function (axiosInstance: AxiosInstance, data: ListFriendRequestsAPIRequest) {
        return axiosInstance.get<ListFriendRequestsAPIResponse>('/common-online-chat-api/friend-requests', {
            params: data
        })
    },
    create: function (axiosInstance: AxiosInstance, data: CreateFriendRequestAPIRequest) {
        return axiosInstance.post<FriendRequestAPIResponse>('/common-online-chat-api/friend-requests', data)
    },
    accept: function (axiosInstance: AxiosInstance, data: { friendRequestId: string }) {
        return axiosInstance.post<FriendRequestAPIResponse>('/common-online-chat-api/friend-requests/:friendRequestId/accept', undefined, { pathVars: { friendRequestId: data.friendRequestId } })
    },
    reject: function (axiosInstance: AxiosInstance, data: { friendRequestId: string }) {
        return axiosInstance.post<FriendRequestAPIResponse>('/common-online-chat-api/friend-requests/:friendRequestId/reject', undefined, { pathVars: { friendRequestId: data.friendRequestId } })
    },
}

export default friendRequestApi