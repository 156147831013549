import { CometChat } from "@cometchat-pro/chat";
import shareAxiosInstance from '../../../../../utils/axios/shareAxiosInstance';
import fetchGroups from '../../../../../utils/fetchGroups';
import * as enums from "../../../util/enums.js";
import { UIKitSettings } from '../../../util/UIKitSettings.js';


export class GroupListManager {

    groupRequest = null;
    groupListenerId = "grouplist_" + new Date().getTime();

    constructor(context) {
        const groupListMode = context.UIKitSettings.groupListMode;
        const groupListModeOptions = UIKitSettings.groupListFilterOptions;
        if (groupListMode === groupListModeOptions.ALL) {
            this.groupRequest = new CometChat.GroupsRequestBuilder().setLimit(30).build();
        } else if (groupListMode === groupListModeOptions.JOINED) {
            this.groupRequest = new CometChat.GroupsRequestBuilder().setLimit(30).joinedOnly(true).build();
        } else {
            this.groupRequest = new CometChat.GroupsRequestBuilder().setLimit(0).build();
        }
        this.dispatch = context.dispatch;
        this.options = context.options;
    }

    fetchGroups() {
        const groupRequest = this.groupRequest;
        const axiosInstance = shareAxiosInstance(this.dispatch, this.options);

        return fetchGroups(groupRequest, axiosInstance);
    }

    attachListeners(callback) {

        CometChat.addGroupListener(
            this.groupListenerId,
            new CometChat.GroupListener({
                onGroupMemberScopeChanged: (message, changedUser, newScope, oldScope, changedGroup) => {
                    callback(enums.GROUP_MEMBER_SCOPE_CHANGED, message, changedGroup, { "user": changedUser, "scope": newScope });
                },
                onGroupMemberKicked: (message, kickedUser, kickedBy, kickedFrom) => {
                    callback(enums.GROUP_MEMBER_KICKED, message, kickedFrom, { "user": kickedUser, "hasJoined": false });
                },
                onGroupMemberBanned: (message, bannedUser, bannedBy, bannedFrom) => {
                    callback(enums.GROUP_MEMBER_BANNED, message, bannedFrom, { "user": bannedUser, "hasJoined": false });
                },
                onGroupMemberUnbanned: (message, unbannedUser, unbannedBy, unbannedFrom) => {
                    callback(enums.GROUP_MEMBER_UNBANNED, message, unbannedFrom, { "user": unbannedUser, "hasJoined": false });
                },
                onMemberAddedToGroup: (message, userAdded, userAddedBy, userAddedIn) => {
                    callback(enums.GROUP_MEMBER_ADDED, message, userAddedIn, { "user": userAdded, "hasJoined": true });
                },
                onGroupMemberLeft: (message, leavingUser, group) => {
                    callback(enums.GROUP_MEMBER_LEFT, message, group, { "user": leavingUser });
                },
                onGroupMemberJoined: (message, joinedUser, joinedGroup) => {
                    callback(enums.GROUP_MEMBER_JOINED, message, joinedGroup, { "user": joinedUser });
                }
            })
        );
    }

    removeListeners() {
        CometChat.removeGroupListener(this.groupListenerId);
    }
}