import { ActiveFlag } from '../../../../../enums/ActiveFlag'
import defaultContactApi from "../../../../../services/onlineChat/defaultContactApi"
import cbdsApi from '../../../../../services/systemMaster/cbdsApi'
import userApi from '../../../../../services/user/userApi'
import shareAxiosInstance from '../../../../../utils/axios/shareAxiosInstance'

export class SendFriendRequestManager {

    constructor(context) {
        this.dispatch = context.dispatch
        this.options = context.options
    }

    fetchTeams() {
        const axiosInstance = shareAxiosInstance(this.dispatch, this.options)
        return cbdsApi.getActiveCompanysByActive(axiosInstance)
            .then(response => response.data)
            .then(companies => companies.filter(company => company.activeFlag === ActiveFlag.ACTIVE))
    }

    fetchUsers(cbdsUid) {
        const axiosInstance = shareAxiosInstance(this.dispatch, this.options)
        const usersPromise = userApi.list(axiosInstance, { cbdsUidIn: [cbdsUid], active: true, activateChat: true })
            .then(response => response.data.data)
        const defaultContactPromise = defaultContactApi.get(axiosInstance, { cbdsUid })
            .then(response => response.data)
        return Promise.all([usersPromise, defaultContactPromise])
            .then(([users, defaultContact]) => {
                users.forEach(user => user.default = user.account.loginId === defaultContact.loginId)
                return users
            })
    }

}