import { CometChat } from "@cometchat-pro/chat"
import { debounce } from '@material-ui/core'
import PropTypes from "prop-types"
import React from "react"
import { onlineChatActions } from '../../../../layouts/OnlineChat/onlineChatSlice'
import Translator from "../../resources/localization/translator"
import { theme } from "../../resources/theme"
import { CometChatContextProvider } from "../../util/CometChatContext"
import * as enums from "../../util/enums.js"
import { CometChatIncomingCall, CometChatIncomingDirectCall } from "../Calls"
import { CometChatMessages } from "../Messages"
import { CometChatFileSender } from './CometChatFileSender'
import { CometChatNavBar } from "./CometChatNavBar"
import {
	unifiedMainStyle, unifiedSidebarStyle, unifiedStyle
} from "./style"

class CometChatUI extends React.Component {

	loggedInUser = null;

	constructor(props) {

		super(props);
		this.state = {
			sidebarview: false,
			sendfileview: false,
			droppedFiles: [],
		}

		this.navBarRef = React.createRef();
		this.messagesRef = React.createRef();
		this.composerRef = React.createRef();
		this.contextProviderRef = React.createRef();
	}

	componentDidMount() {

		if (this.props.chatWithUser.length === 0 && this.props.chatWithGroup.length === 0) {
			this.toggleSideBar();
		}
	}

	navBarAction = (action, type, item) => {

		switch (action) {
			case enums.ACTIONS["ITEM_CLICKED"]:
				this.itemClicked(item, type);
				break;
			case enums.ACTIONS["TOGGLE_SIDEBAR"]:
				this.toggleSideBar();
				break;
			default:
				break;
		}
	}

	itemClicked = (item, type) => {
		this.contextProviderRef.setTypeAndItem(type, item);
		this.toggleSideBar()
	}

	updateUnreadMessageCount = debounce(() => {
		this.props.dispatch(onlineChatActions.updateUnreadMessageCount())
	});

	actionHandler = (action, item, count, ...otherProps) => {
		switch (action) {
			case enums.ACTIONS["MESSAGE_READ"]:
				this.updateUnreadMessageCount();
				break;
			case enums.ACTIONS["TOGGLE_SIDEBAR"]:
				this.toggleSideBar();
				break;
			case enums.GROUP_MEMBER_SCOPE_CHANGED:
			case enums.GROUP_MEMBER_KICKED:
			case enums.GROUP_MEMBER_BANNED:
				this.groupUpdated(action, item, count, ...otherProps);
				break;
			case "DROP_FILES":
				this.dropFilesHandler(item, count, ...otherProps);
				break;
			case "SEND_FILES":
				this.sendFilesHandler(item, count, ...otherProps);
				break;
			case "CANCEL_SEND_FILES":
				this.cancelSendFilesHandler(item, count, ...otherProps);
				break;
			default:
				break;
		}
	}

	toggleSideBar = () => {
		const sidebarview = this.state.sidebarview;
		this.setState({ sidebarview: !sidebarview });
	}

	dropFilesHandler = (item, type, files) => {
		this.setState({
			sendfileview: true,
			droppedFiles: Array.from(files),
		});
	}

	sendFilesHandler = () => {
		this.cancelSendFilesHandler();
		this.messagesRef.sendFiles(this.state.droppedFiles);
	}

	getReceiverDetails = (item, type) => {
		let receiverId;
		let receiverType;

		if (type === CometChat.ACTION_TYPE.TYPE_USER) {
			receiverId = item.uid;
			receiverType = CometChat.RECEIVER_TYPE.USER;
		} else if (type === CometChat.ACTION_TYPE.TYPE_GROUP) {
			receiverId = item.guid;
			receiverType = CometChat.RECEIVER_TYPE.GROUP;
		}

		return { receiverId: receiverId, receiverType: receiverType };
	};

	cancelSendFilesHandler = () => {
		this.setState({
			sendfileview: false,
			droppedFiles: [],
		})
	}

	/**
	 If the logged in user is banned, kicked or scope changed, update the chat window accordingly
	 */
	groupUpdated = (key, message, group, options) => {

		switch (key) {
			case enums.GROUP_MEMBER_BANNED:
			case enums.GROUP_MEMBER_KICKED: {

				if (this.contextProviderRef.state.type === CometChat.ACTION_TYPE.TYPE_GROUP
					&& this.contextProviderRef.state.item.guid === group.guid
					&& options.user.uid === this.loggedInUser.uid) {

					this.contextProviderRef.setItem({});
					this.contextProviderRef.setType("");
				}
				break;
			}
			case enums.GROUP_MEMBER_SCOPE_CHANGED: {

				if (this.contextProviderRef.state.type === CometChat.ACTION_TYPE.TYPE_GROUP
					&& this.contextProviderRef.state.item.guid === group.guid
					&& options.user.uid === this.loggedInUser.uid) {

					const newObject = Object.assign({}, this.contextProviderRef.state.item, { "scope": options["scope"] })
					this.contextProviderRef.setItem(newObject);
					this.contextProviderRef.setType(CometChat.ACTION_TYPE.TYPE_GROUP);
				}
				break;
			}
			default:
				break;
		}
	}

	render() {

		let messageScreen = (
			<CometChatMessages
				ref={el => this.messagesRef = el}
				theme={this.props.theme}
				lang={this.props.lang}
				_parent="unified"
				actionGenerated={this.actionHandler} />
		);

		let fileSender = null
		if (this.state.sendfileview) {
			fileSender = <CometChatFileSender theme={this.props.theme} lang={this.props.lang} files={this.state.droppedFiles} actionGenerated={this.actionHandler} />
		}
		return (
			<CometChatContextProvider ref={el => this.contextProviderRef = el} user={this.props.chatWithUser} group={this.props.chatWithGroup} language={this.props.lang}>
				<div css={unifiedStyle(this.props)} className="cometchat cometchat--unified" dir={Translator.getDirection(this.props.lang)}>
					<div css={unifiedSidebarStyle(this.props)} className="unified__sidebar">
						<CometChatNavBar
							ref={el => this.navBarRef = el}
							theme={this.props.theme}
							newFriendRequestCount={this.props.newFriendRequestCount}
							actionGenerated={this.navBarAction} />
					</div>
					<div css={unifiedMainStyle(this.props)} className="unified__main">
						{messageScreen}
					</div>
					<CometChatIncomingCall theme={this.props.theme} lang={this.props.lang} actionGenerated={this.actionHandler} />
					<CometChatIncomingDirectCall theme={this.props.theme} lang={this.props.lang} actionGenerated={this.actionHandler} />
					{fileSender}
				</div>
			</CometChatContextProvider>
		);
	}
}

// Specifies the default values for props:
CometChatUI.defaultProps = {
	lang: Translator.getDefaultLanguage(),
	theme: theme,
	chatWithUser: "",
	chatWithGroup: "",
};

CometChatUI.propTypes = {
	lang: PropTypes.string,
	theme: PropTypes.object,
	chatWithUser: PropTypes.string,
	chatWithGroup: PropTypes.string,
}

export { CometChatUI }

