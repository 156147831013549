import { AxiosInstance } from 'axios'
import { PageResultEntity } from '../../utils/PageResultEntity'

interface UserAPIResponse {
    loginId: string,
    companyId: string,
    uid: string,
    authToken?: string,
}

interface ListUserAPIRequest {
    loginIdIn?: string[],
    loginContains?: string,
    companyIn?: string[],
    companyContains?: string,
    uidIn?: string[],
    uidContains?: string,
    authTokenIn?: string[],
    authTokenContains?: string,
    page?: {
        currentPage: number,
        pageSize: number,
    }
}
const userApi = {
    get: function (axiosInstance: AxiosInstance) {
        return axiosInstance.get<UserAPIResponse>('/common-online-chat-api/user')
    },

    list: function (axiosInstance: AxiosInstance, data: ListUserAPIRequest) {
        return axiosInstance.post<PageResultEntity<UserAPIResponse>>('/common-online-chat-api/list', data)
    }
}

export default userApi