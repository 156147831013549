import { CometChat } from "@cometchat-pro/chat";
import shareAxiosInstance from '../../../../../utils/axios/shareAxiosInstance';
import fetchUsers from '../../../../../utils/fetchUsers';
import { UIKitSettings } from "../../../util/UIKitSettings";


export class UserListManager {

    userRequest = null;
    userListenerId = "userlist_" + new Date().getTime();

    constructor(context) {
        const userListMode = context.UIKitSettings.userListMode;
        const userListModeOptions = UIKitSettings.userListFilterOptions;
        if (userListMode === userListModeOptions.ALL) {
            this.userRequest = new CometChat.UsersRequestBuilder().setLimit(30).build();
        } else if (userListMode === userListModeOptions.FRIENDS) {
            this.userRequest = new CometChat.UsersRequestBuilder().setLimit(30).friendsOnly(true).build();
        } else {
            this.userRequest = new CometChat.UsersRequestBuilder().setLimit(0).build();
        }
        this.dispatch = context.dispatch;
        this.options = context.options;
    }

    fetchUsers() {
        const userRequest = this.userRequest;
        const axiosInstance = shareAxiosInstance(this.dispatch, this.options);

        return fetchUsers(userRequest, axiosInstance);
    }

    attachListeners(callback) {

        CometChat.addUserListener(
            this.userListenerId,
            new CometChat.UserListener({
                onUserOnline: onlineUser => {
                    /* when someuser/friend comes online, user will be received here */
                    callback(onlineUser);
                },
                onUserOffline: offlineUser => {
                    /* when someuser/friend went offline, user will be received here */
                    callback(offlineUser);
                }
            })
        );
    }

    removeListeners() {

        CometChat.removeUserListener(this.userListenerId);
    }
}