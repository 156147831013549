import { AxiosInstance } from 'axios'
import cbdsApi from "../services/systemMaster/cbdsApi"

export default function fetchUsersMetadata(users: any[], axiosInstance: AxiosInstance) {
    const cbdsUids = users.map(user => user.metadata.cbdsUid!!)
    return cbdsApi.getCompanyByUids(axiosInstance, cbdsUids)
        .then(cbdssResponse => {
            const cbdss = cbdssResponse.data
            const cbdsMap = cbdss.reduce((record, cbds) => {
                record[cbds.cbdsUid] = cbds
                return record
            }, {} as any)
            return users
                .map(user => {
                    const cbdsInfo = cbdsMap[user.metadata.cbdsUid]
                    user.metadata = { ...user.metadata, cbdsCode: cbdsInfo?.cbdsCode ?? '', cbdsName: cbdsInfo?.cbdsName ?? '' }
                    return user
                })
        })
}